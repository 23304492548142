import React from 'react';

const SocialIcon = ({ icon, url }) => {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt="social media icon" style={{ width: '24px', height: '24px' }} className="social-icon" />
        </a>
    );
};

export default SocialIcon;